export { default as logo } from "../assets/main_logo.png";
export { default as logo_christmas } from "../assets/main_logo_christmas.png";
export { default as banner3 } from "../assets/banner3.png";
export { default as QR_koda } from "../assets/QR-koda.png";
export { default as Puscica } from "../assets/puscica.png";
export { default as logo_adobe } from "../assets/logo_adobe.png";

// Footer
export { default as logo_rutart } from "../assets/logo_rutart.png";

// Socials
export { default as logo_facebook } from "../assets/facebook_logo.png";
export { default as logo_instagram } from "../assets/instagram_logo.png";
export { default as logo_linkedin } from "../assets/linkedin_logo.png";
export { default as facebook_working } from "../assets/facebook_working.png";
export { default as instagram_working } from "../assets/instagram_working.png";

// Button
export { default as naroci_arrow_large } from "../assets/button/blue_button_arrow_large.png";
export { default as naroci_arrow_small } from "../assets/button/blue_button_arrow_small.png";
export { default as vprasaj_arrow_large } from "../assets/button/red_button_arrow_large.png";
export { default as vprasaj_arrow_small } from "../assets/button/red_button_arrow_small.png";

// Gallery arrows
export { default as gallery_close } from "../assets/image-gallery/gallery-close.png";
export { default as gallery_left } from "../assets/image-gallery/gallery-left.png";
export { default as gallery_right } from "../assets/image-gallery/gallery-right.png";
export { default as gallery_mobile_left } from "../assets/image-gallery/gallery_mobile_left.png";
export { default as gallery_mobile_right } from "../assets/image-gallery/gallery_mobile_right.png";

// Images - Gallery1
export const Gallery1Assets: Record<string, string> = {
  gallery1_1: require("../assets/gallery1/image1.webp"),
  gallery1_2: require("../assets/gallery1/image2.webp"),
  gallery1_3: require("../assets/gallery1/image3.webp"),
  gallery1_4: require("../assets/gallery1/image4.webp"),
  gallery1_5: require("../assets/gallery1/image5.webp"),
  gallery1_6: require("../assets/gallery1/image6.webp"),
  gallery1_7: require("../assets/gallery1/image7.webp"),
  gallery1_8: require("../assets/gallery1/image8.webp"),
  gallery1_9: require("../assets/gallery1/image9.webp"),
  gallery1_10: require("../assets/gallery1/image10.webp"),
  gallery1_11: require("../assets/gallery1/image11.webp"),
  gallery1_12: require("../assets/gallery1/image12.webp"),
  gallery1_13: require("../assets/gallery1/image13.webp"),
  gallery1_14: require("../assets/gallery1/image14.webp"),
  gallery1_15: require("../assets/gallery1/image15.webp"),
  gallery1_16: require("../assets/gallery1/image16.webp"),
  gallery1_17: require("../assets/gallery1/image17.webp"),
  gallery1_18: require("../assets/gallery1/image18.webp"),
};

// Gallery2
export const Gallery2Assets: Record<string, string> = {
  gallery2_1: require("../assets/gallery2/image1.webp"),
  gallery2_2: require("../assets/gallery2/image2.webp"),
  gallery2_3: require("../assets/gallery2/image3.webp"),
  gallery2_4: require("../assets/gallery2/image4.webp"),
  gallery2_5: require("../assets/gallery2/image5.webp"),
  gallery2_6: require("../assets/gallery2/image6.webp"),
  gallery2_7: require("../assets/gallery2/image7.webp"),
  gallery2_8: require("../assets/gallery2/image8.webp"),
  gallery2_9: require("../assets/gallery2/image9.webp"),
  gallery2_10: require("../assets/gallery2/image10.webp"),
  gallery2_11: require("../assets/gallery2/image11.webp"),
  gallery2_12: require("../assets/gallery2/image12.webp"),
  gallery2_13: require("../assets/gallery2/image13.webp"),
  gallery2_14: require("../assets/gallery2/image14.webp"),
  gallery2_15: require("../assets/gallery2/image15.webp"),
  gallery2_16: require("../assets/gallery2/image16.webp"),
  gallery2_17: require("../assets/gallery2/image17.webp"),
  gallery2_18: require("../assets/gallery2/image18.webp"),
  gallery2_19: require("../assets/gallery2/image19.webp"),
  gallery2_20: require("../assets/gallery2/image20.webp"),
  gallery2_21: require("../assets/gallery2/image21.webp"),
};
